import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location, LocationListener, LocationState } from 'history';

export const ScrollHistory: React.FC = () => {
  const history = useHistory();
  const [previousLocation, setPreviousLocation] = useState<Location<LocationState> | null>(history.location);

  const onHistoryChange = useCallback<LocationListener<LocationState>>(({ ...location }, action) => {
    if (action === 'PUSH' && location.key != undefined) {
      setOffset(location.key, { x: window.scrollX, y: window.scrollY }, "scroll-history");
      setPreviousLocation(location);
    }

    window.requestAnimationFrame(() => {
      if (previousLocation?.pathname == location.pathname) { return; }
      const previousKey = previousLocation?.key ?? '';
      const key = action === 'POP' ? previousKey : location.key ?? previousKey;
      const { x, y } = getOffset(key, action, "scroll-history");
      window.scrollTo(x, y);
    });
  }, [previousLocation]);

  useEffect(() => history.listen(onHistoryChange), [history, onHistoryChange]);

  return null;
};

const getOffset = (key: string, action: string, storageKey: string) => {
  if (action === 'PUSH') { return { x: 0, y: 0 }; }

  const storageItem = sessionStorage.getItem(storageKey);
  const keys = JSON.parse(storageItem ?? 'null') ?? {};
  return keys[key] || { x: 0, y: 0 };
};

const setOffset = (key: string, value: { x: number, y: number }, storageKey: string) => {
  const storageItem = sessionStorage.getItem(storageKey);
  let keys = JSON.parse(storageItem ?? 'null') ?? {};
  keys = { ...keys, ...{ [key]: value } };
  sessionStorage.setItem(storageKey, JSON.stringify(keys));
};
