import { PADDLE_VENDOR_ID, SANDBOX_ENVIRONMENT } from '@Constant';
import { useCallback, useState } from 'react';

class PaddleHelper {
  constructor(vendorId: number, isSandbox: boolean, onReady?: () => void) {
    this.onReady = onReady;
    const script = document.createElement('script');
    script.src = 'https://cdn.paddle.com/paddle/paddle.js';
    script.onload = () => this.initPaddle(vendorId, isSandbox);
    document.body.appendChild(script);
  }

  private readonly onReady: (() => void) | undefined;

  private listeners: Map<Paddle.PaddleEventType, Paddle.PaddleEventCallback[]> = new Map();

  private initPaddle = (vendorId: number, isSandbox: boolean) => {
    if (isSandbox) {
      Paddle.Environment.set('sandbox');
    }
    Paddle.Setup({
      vendor: vendorId,
      eventCallback: this.onCallback,
    });
    this.onReady?.();
  };

  private onCallback = (data: Paddle.PaddleEvent) => {
    const listeners = this.listeners.get(data.event) ?? [];
    listeners.forEach((listener) => listener(data));
  };

  public subscribe = (eventName: Paddle.PaddleEventType, callback: Paddle.PaddleEventCallback) => {
    this.listeners.set(eventName, [...this.listeners.get(eventName) ?? [], callback]);
  };

  public unsubscribe = (eventName: Paddle.PaddleEventType, callback: Paddle.PaddleEventCallback) => {
    this.listeners.set(eventName, (this.listeners.get(eventName) ?? []).filter((listener) => listener !== callback));
  };
}

export const usePaddle = () => {
  const [isReady, setIsReady] = useState(false);

  const onReady = useCallback(() => {
    setIsReady(true);
  }, []);

  const helper = new PaddleHelper(PADDLE_VENDOR_ID, SANDBOX_ENVIRONMENT, onReady);

  return [isReady, helper] as const;
};
