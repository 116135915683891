export * from './Currency';
export * from './Release';

export const API_BASE = process.env.REACT_APP_API_HOST || 'https://api.gifox.app';
export const SERVICE_PROXY = process.env.REACT_APP_SERVICE_PROXY;

export const MAIL_SUPPORT = 'team@gifox.app';
export const URL_GITHUB_TRACKER = 'https://github.com/gifox/tracker/issues';
export const URL_PRODUCTHUNT = 'https://www.producthunt.com/posts/gifox';
export const URL_TWITTER = 'https://twitter.com/gifoxapp';
export const URL_APPSTORE = 'https://apps.apple.com/app/gifox-2/id1461845568?ls=1&mt=12';
export const URL_SETAPP = 'https://go.setapp.com/stp122?refAppID=197';
export const URL_DOWNLOAD_LATEST = '/download/latest';
export const URL_ORIGINAL = '/download/original';

export const SANDBOX_ENVIRONMENT = process.env.REACT_APP_SANDBOX_ENVIRONMENT === 'true' || false;
export const PADDLE_PRODUCT_ID = Number(process.env.REACT_APP_PADDLE_PRODUCT_ID);
export const PADDLE_VENDOR_ID = Number(process.env.REACT_APP_PADDLE_VENDOR_ID);
export const PADDLE_CUSTOM_CHECKOUT_URL = process.env.REACT_APP_SANDBOX_ENVIRONMENT === 'true' ? 'https://sandbox-checkout-service.paddle.com/create/checkout/custom' : 'https://checkout-service.paddle.com/create/checkout/custom';
